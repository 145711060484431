import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.42LHJR2F.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n,
  o,
  r as r3
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-home-page-hero/jb-home-page-hero.scss?inline
var jb_home_page_hero_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
.subline {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

.text, .headline {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

.subline {
  font-size: var(--theme-typo-uppercase-700-fontsize, 0.688rem);
  letter-spacing: var(--theme-typo-uppercase-700-letterspacing, 0.063rem);
  line-height: var(--theme-typo-uppercase-700-lineheight, 1rem);
  text-transform: uppercase;
}

.headline {
  font-size: var(--theme-typo-h1-mobile-fontsize, 2.125rem);
  line-height: var(--theme-typo-h1-mobile-lineheight, 2.75rem);
}
@media (min-width: 1281px) {
  .headline {
    font-size: var(--theme-typo-h1-desktop-fontsize, 3.125rem);
    line-height: var(--theme-typo-h1-desktop-lineheight, 4.375rem);
  }
}

.text {
  font-size: var(--theme-typo-p-mobile-fontsize, 1.063rem);
  line-height: var(--theme-typo-p-mobile-lineheight, 1.875rem);
}
@media (min-width: 1281px) {
  .text {
    font-size: var(--theme-typo-p-desktop-fontsize, 1.188rem);
    line-height: var(--theme-typo-p-desktop-lineheight, 2.125rem);
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
.text-container, .title-container, .image-container, section {
  --_grid-gap: 16px;
  --_grid-template-columns: repeat(12, 1fr);
  --_outer-padding: calc(6.68% - var(--_grid-gap));
  --_max-width: 1400px;
  display: grid;
  gap: var(--grid-row-gap, 0) var(--grid-column-gap, var(--_grid-gap));
  grid-template-columns: [full-start] var(--_outer-padding) [main-start] var(--theme-grid-template-columns, var(--_grid-template-columns)) [main-end] var(--_outer-padding) [full-end];
  grid-template-rows: 1fr;
  padding-inline-end: var(--compensate-scrollbar);
}
@media (min-width: 600px) {
  .text-container, .title-container, .image-container, section {
    --_outer-padding: calc(8.35% - var(--_grid-gap));
  }
}
@media (min-width: 960px) {
  .text-container, .title-container, .image-container, section {
    --_grid-gap: 40px;
    --_outer-padding: calc(6.955% - var(--_grid-gap) / 2);
  }
}
@media (min-width: 1437px) {
  .text-container, .title-container, .image-container, section {
    --_outer-padding: calc((100% - var(--theme-grid-max-width, var(--_max-width)) - var(--_grid-gap) * 2) / 2);
  }
}

:host {
  color: var(--host-color, #141e55);
}

:host {
  --_before-subline-padding: var(--spacing-s);
  --_after-subline-padding: var(--spacing-xs);
  --_after-headline-padding: var(0);
  --_before-text-padding: var(--spacing-xs);
  --_before-button-padding: var(--spacing-m);
  --_title-color: var(--theme-color-royal-blue);
  --_after-line-padding: var(--spacing-m);
  --_start-radial-shade-at: 20%;
  --_start-radial-blur-at: 20%;
}

section {
  padding-inline-end: 0;
}

.image-container {
  display: grid;
  grid-column: 1/15;
  grid-row: 1;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

::slotted(picture) {
  aspect-ratio: 1/1;
  block-size: 100%;
  display: block;
  grid-column: 1/15;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
}
@media (min-width: 600px) {
  ::slotted(picture) {
    aspect-ratio: 16/9;
  }
}
@media (min-width: 960px) {
  ::slotted(picture) {
    aspect-ratio: 21/9;
  }
}
@media (min-width: 1281px) {
  ::slotted(picture) {
    aspect-ratio: 5/2;
  }
}
@media (min-width: 1437px) {
  ::slotted(picture) {
    aspect-ratio: 8/3;
  }
}

.over-image-line {
  block-size: 0;
  grid-column: 2/14;
  inset-block-end: 0;
}
.over-image-line::before, .over-image-line::after {
  background-color: rgb(var(--theme-color-white));
  block-size: 32px;
  content: "";
  display: block;
  grid-column: 2/14;
  inline-size: 1px;
  transform: translateY(-100%);
  z-index: 15;
}
@media (min-width: 600px) {
  .over-image-line::before, .over-image-line::after {
    block-size: 51px;
  }
}
@media (min-width: 960px) {
  .over-image-line::before, .over-image-line::after {
    display: none;
  }
}

.blur-filter {
  display: none;
}

@media (min-width: 960px) {
  .behind-text-blur .behind-text {
    backdrop-filter: blur(40px);
    display: block;
    height: 100%;
    inline-size: 100%;
    inset-block-end: 0;
    mask-image: radial-gradient(at var(--_start-radial-blur-at) 100%, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 60%);
    opacity: 0.6;
    position: absolute;
    z-index: 2;
  }
}
@media (min-width: 1281px) {
  .behind-text-blur .behind-text {
    --_start-radial-blur-at: 30%;
  }
}
@media (min-width: 1437px) {
  .behind-text-blur .behind-text {
    --_start-radial-blur-at: 35%;
  }
}

.shading-filter {
  display: none;
  height: 100%;
  inline-size: 100%;
  position: absolute;
  z-index: 11;
}

@media (min-width: 960px) {
  .radial-shade .shading-filter.radial {
    background: radial-gradient(at var(--_start-radial-shade-at) 90%, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.15) 45%, rgba(0, 0, 0, 0.05) 80%);
    block-size: 100%;
    display: block;
    inline-size: 100%;
  }
}
@media (min-width: 1281px) {
  .radial-shade .shading-filter.radial {
    --_start-radial-shade-at: 30%;
  }
}
@media (min-width: 1281px) {
  .radial-shade .shading-filter.radial {
    --_start-radial-shade-at: 35%;
  }
}

@media (min-width: 960px) {
  .headline-shade .subline,
  .headline-shade .headline {
    text-shadow: 0 0 160px #000, 0 0 250px #000, 0 0 250px #000;
  }
}

.subline::before,
.subline::after,
.headline::before,
.headline::after,
.text::before,
.text::after,
.button-container::before,
.button-container::after {
  border-inline-start-style: solid;
  border-inline-start-width: 1px;
  content: "";
  inline-size: 1px;
  margin-inline-end: var(--_after-line-padding);
  z-index: 2;
}
@media (min-width: 960px) {
  .subline::before,
  .subline::after,
  .headline::before,
  .headline::after,
  .text::before,
  .text::after,
  .button-container::before,
  .button-container::after {
    --_after-line-padding: var(--spacing-xl);
  }
}

.title-container > *,
.text-container > * {
  display: flex;
}

.title-container {
  clip-path: polygon(0 -100%, 100% -100%, 100% 100%, 0% 100%);
  color: rgb(var(--_title-color));
  display: grid;
  grid-column: 1/15;
  grid-row: 2;
  position: relative;
}
@media (min-width: 960px) {
  .title-container {
    --_title-color: var(--theme-color-white);
    align-self: end;
    grid-row: 1;
    grid-template-rows: 1fr min-content;
    z-index: 5;
  }
}

.subline {
  grid-column: 2/14;
  padding-block: var(--_before-subline-padding) var(--_after-subline-padding);
  text-transform: uppercase;
  z-index: inherit;
}
.subline::before, .subline::after {
  block-size: calc(100% + var(--_after-subline-padding) + var(--_before-subline-padding));
  margin-block-start: calc(-1 * var(--_before-subline-padding));
}
@media (min-width: 960px) {
  .subline {
    --_before-subline-padding: 0;
    --_after-subline-padding: var(--spacing-s);
    font-size: var(--theme-typo-uppercase-900-fontsize);
    font-weight: var(--theme-font-black-weight, 900);
    grid-column: 2/span 6;
    letter-spacing: var(--theme-typo-uppercase-900-letterspacing);
    line-height: var(--theme-typo-uppercase-900-lineheight);
  }
  .subline::before, .subline::after {
    margin-block: 0 calc(-1 * var(--_after-subline-padding));
  }
}
@media (min-width: 1281px) {
  .subline {
    grid-column: 2/span 9;
  }
}

.headline {
  font-size: var(--theme-typo-h1-mobile-fontsize);
  grid-column: 2/14;
  line-height: var(--theme-typo-h1-mobile-lineheight);
  padding-block-end: var(--_after-headline-padding);
  z-index: inherit;
}
.headline::before, .headline::after {
  block-size: calc(100% + var(--_after-headline-padding));
  margin-block-end: calc(-1 * var(--_after-headline-padding));
}
@media (min-width: 960px) {
  .headline {
    --_after-headline-padding: var(--spacing-xl);
    grid-column: 2/span 6;
  }
}
@media (min-width: 1281px) {
  .headline {
    --_after-headline-padding: var(--spacing-l);
    font-size: var(--theme-typo-h1-desktop-fontsize);
    line-height: var(--theme-typo-h1-desktop-lineheight);
  }
}

.text-container {
  display: grid;
  grid-column: 1/15;
  grid-row: 3;
  position: relative;
}

.text {
  grid-column: 2/14;
  justify-self: flex-start;
  padding-block-start: var(--_before-text-padding);
  text-align: start;
}
.text::before, .text::after {
  block-size: calc(100% + var(--_before-text-padding));
  margin-block-start: calc(-1 * var(--_before-text-padding));
}
@media (min-width: 960px) {
  .text {
    --_before-text-padding: var(--spacing-s);
    grid-column-end: 11;
  }
}
@media (min-width: 1281px) {
  .text {
    --_before-text-padding: var(--spacing-xs);
    font-size: var(--theme-typo-p-big-desktop-fontsize);
    grid-column-end: 9;
    line-height: var(--theme-typo-p-big-desktop-lineheight);
  }
}

.button-container {
  display: flex;
  grid-column: 2/14;
  padding-block-start: var(--_before-button-padding);
}
.button-container jb-button-primary {
  inline-size: 100%;
  text-wrap: nowrap;
}
@media (min-width: 960px) {
  .button-container jb-button-primary {
    inline-size: max-content;
  }
}
.button-container::before, .button-container::after {
  block-size: calc(100% + var(--_before-button-padding));
  display: none;
  margin-block-start: calc(-1 * var(--_before-button-padding));
}
@media (min-width: 960px) {
  .button-container {
    grid-column: 2/5;
    transform: translateY(-1px);
    --_before-button-padding: var(--spacing-xs);
  }
  .button-container::before, .button-container::after {
    display: block;
  }
}

.button-wrapper {
  inline-size: 100%;
}
@media (min-width: 960px) {
  .button-wrapper {
    inline-size: 600px;
  }
}

:not(.align-end) .title-container > *::after,
:not(.align-end) .text-container > *::after {
  display: none;
}

.align-end .blur-filter.behind-text {
  --_start-radial-blur-at: 80%;
}
@media (min-width: 1281px) {
  .align-end .blur-filter.behind-text {
    --_start-radial-blur-at: 70%;
  }
}
@media (min-width: 1437px) {
  .align-end .blur-filter.behind-text {
    --_start-radial-blur-at: 65%;
  }
}
.align-end .shading-filter.radial {
  --_start-radial-shade-at: 80%;
}
@media (min-width: 1281px) {
  .align-end .shading-filter.radial {
    --_start-radial-shade-at: 70%;
  }
}
@media (min-width: 1437px) {
  .align-end .shading-filter.radial {
    --_start-radial-shade-at: 65%;
  }
}
@media (min-width: 960px) {
  .align-end .title-container > *::before,
  .align-end .text-container > *::before {
    display: none;
  }
}
.align-end .title-container > *::after,
.align-end .text-container > *::after {
  display: none;
}
@media (min-width: 960px) {
  .align-end .title-container > *::after,
  .align-end .text-container > *::after {
    display: block;
    inline-size: 1px;
    margin-inline: var(--_after-line-padding) 0;
  }
}
.align-end .title-container .subline {
  grid-column: 2/14;
  text-align: start;
}
@media (min-width: 960px) {
  .align-end .title-container .subline {
    grid-column: span 7/14;
    justify-content: flex-end;
    text-align: end;
  }
}
.align-end .title-container .headline {
  grid-column: 2/14;
  text-align: start;
}
@media (min-width: 960px) {
  .align-end .title-container .headline {
    grid-column: span 7/14;
    justify-content: flex-end;
    text-align: end;
  }
}
@media (min-width: 960px) {
  .align-end .text-container .text {
    grid-column: span 7/14;
    justify-self: flex-end;
    text-align: end;
  }
}
@media (min-width: 1281px) {
  .align-end .text-container .text {
    grid-column-start: 7;
  }
}
@media (min-width: 960px) {
  .align-end .text-container .button-container {
    grid-column: 2/14;
    justify-self: flex-end;
  }
}
.align-end .text-container .button-wrapper {
  text-align: end;
}`;

// src/components/jb-home-page-hero/jb-home-page-hero.ts
var JbHomePageHero = class extends r2 {
  constructor() {
    super(...arguments);
    this._classes = { "behind-text-blur": false, "radial-shade": false, "headline-shade": false };
  }
  connectedCallback() {
    super.connectedCallback();
    this._classes = {
      ...this._classes,
      [`align-${this.align ?? "start"}`]: true
    };
  }
  firstUpdated() {
    const img = this._assignedElements[0].querySelector("img");
    if (!img.complete) {
      img.addEventListener(
        "load",
        () => {
          this._setDecorations();
        },
        { once: true }
      );
    } else {
      this._setDecorations();
    }
  }
  _setDecorations() {
    this._classes = {
      ...this._classes,
      "behind-text-blur": this.behindTextBlur,
      "radial-shade": this.radialShade,
      "headline-shade": this.headlineShade
    };
  }
  _tracking(e) {
    const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e, {
      component_name: "homepage-hero",
      interaction_element: "Internal Links",
      interaction_text: `${this.subline}: ${this.headline}`
    });
    if (jbTrackingEvent) {
      this.dispatchEvent(jbTrackingEvent);
    }
  }
  render() {
    return ke`
      <section class="${Rt(this._classes)}">
        <div class="image-container">
          <div class="shading-filter radial"></div>
          <div class="blur-filter behind-text"></div>

          <slot></slot>

          <div class="over-image-line"></div>
        </div>

        <div class="title-container">
          <span class="subline">${this.subline}</span>
          <h1 class="headline">${this.headline}</h1>
        </div>

        <div class="text-container">
          <p class="text">${this.text}</p>

          <div class="button-container">
            <div class="button-wrapper">
              <jb-button-primary
                .forceHover=${this.button.forceHover}
                .inactive=${this.button.inactive}
                .inverted=${this.button.inverted}
                .link=${this.button.link}
                .name=${this.button.name}
                @jb-link:clicked=${this._tracking}
                >${this.buttonText}</jb-button-primary
              >
            </div>
          </div>
        </div>
      </section>
    `;
  }
};
JbHomePageHero.styles = r(jb_home_page_hero_default);
__decorateClass([
  n()
], JbHomePageHero.prototype, "align", 2);
__decorateClass([
  n({ type: Boolean })
], JbHomePageHero.prototype, "behindTextBlur", 2);
__decorateClass([
  n({ type: Object })
], JbHomePageHero.prototype, "button", 2);
__decorateClass([
  n()
], JbHomePageHero.prototype, "buttonText", 2);
__decorateClass([
  n()
], JbHomePageHero.prototype, "headline", 2);
__decorateClass([
  n({ type: Boolean })
], JbHomePageHero.prototype, "headlineShade", 2);
__decorateClass([
  n({ type: Boolean })
], JbHomePageHero.prototype, "radialShade", 2);
__decorateClass([
  n()
], JbHomePageHero.prototype, "subline", 2);
__decorateClass([
  n()
], JbHomePageHero.prototype, "text", 2);
__decorateClass([
  o({ selector: "picture" })
], JbHomePageHero.prototype, "_assignedElements", 2);
__decorateClass([
  r3()
], JbHomePageHero.prototype, "_classes", 2);
JbHomePageHero = __decorateClass([
  customElement("jb-home-page-hero")
], JbHomePageHero);

export {
  JbHomePageHero
};
